import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Video from "../components/video"
import { Container } from "react-bootstrap";

export default function Home({ data }) {
  return (
    <Layout>
      <Container>
        <h1>NUTFIELD</h1>
      </Container>
      <Container>
      {data.allYoutubeVideo.edges.map(({ node }) => (
            <h2 className="sr-only">{node.title}</h2>
      ))}
        {data.allYoutubeVideo.edges.map(({ node }) => (
            <Video
            videoSrURL={"https://www.youtube.com/embed/" + node.videoId}
            videoTitle={node.title}
          />
      ))}

      </Container>
      <Container>
        <h2>{data.nodePage.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: data.nodePage.body.processed }} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allYoutubeVideo(sort: {fields: publishedAt, order: DESC}, limit: 1) {
      totalCount
      edges {
        node {
          id
          title
          description
          videoId
          publishedAt(formatString: "MMM D, YYYY")
          thumbnail {
            url
          }
        }
      }
    }
    nodePage(drupal_internal__nid: { eq: 4 }) {
      drupal_internal__nid
      title
      body {
        processed
      }
    }
  }
`
